














































































import { Component, Vue } from 'vue-property-decorator'
import { RedCapital, SelectedCountry, TitleTemplatePublic } from '@/configuration'
import { EventBus } from '@/main'

@Component({
  metaInfo: {
    title: 'Ingresar',
    titleTemplate: TitleTemplatePublic,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang,
    }
  },
  data() {
    return {
      showMessage: false,
    }
  },
  created() {
    EventBus.$on('login-dialog-cancelled', () => {
      this.$data.showMessage = true
    })
  },
  mounted() {
    /*setTimeout(() => {
      EventBus.$emit('open-login-dialog', '/')
    }, 1500)*/
  },
  methods: {
    openLoginDialog() {
      EventBus.$emit('open-login-dialog', this.$route.query.return)
    }
  },
})
export default class Login extends Vue {}
